<template>
  <b-modal
      ref="edit-modal-new-vehicle"
      id="editModalNewVehicle"
      size="xl"
      hide-header
      @ok="save"
      @cancel="close"
      ok-title="Salva"
      cancel-title="Chiudi"
  >
    <h4 class="mb-4">Nuovo Veicolo</h4>
    <b-row>
      <!-- vettore -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>Vettore</h6>
        <VectorSelect ref="vectorSelect" @setSelect="(value) => editedItem.vector_id = value" />
      </b-col>
      <!-- service -->
      <b-col cols="12" lg="6" class="mb-3">
        <ServiceSelect ref="serviceSelect" @setSelect="(value) => editedItem.types_of_services_id = value" />
      </b-col>
      <!-- targa -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>Targa</h6>
        <b-form-input
            v-model="editedItem.vehicle_number"
            placeholder="Inserisci Targa"
            name="name-group"
            size="lg"
        ></b-form-input>
      </b-col>
      <!-- registration_date -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>Data Immatricolazione</h6>
        <b-form-datepicker
            id="datepicker-full-width"
            v-model="editedItem.registration_date"
            placeholder="Seleziona Data"
            menu-class="w-100"
            calendar-width="100%"
            :max="new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDay() )"
            class="mb-2"
            :hide-header="false"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="it"
            size="lg"
        ></b-form-datepicker>
      </b-col>
      <!-- note -->
      <b-col cols="12" lg="12" class="mb-3">
        <h6>Note</h6>
        <b-form-textarea
            id="textarea-auto-height"
            rows="3"
            max-rows="8"
            v-model="editedItem.note"
        ></b-form-textarea>
      </b-col>
      <!-- active -->
      <b-col cols="12" lg="12" class="mb-3">
        <b-form-checkbox v-model="editedItem.active" name="check-button" switch size="lg">
          Attivo
        </b-form-checkbox>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "ModalNewVehicle",
  components: {
    VectorSelect: () => import("@/views/users/components/VectorsSelect"),
    ServiceSelect: () => import("@/views/type-of-services/components/Select"),
  },
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        id: "",
        vector_id: 0,
        types_of_services_id: 0,
        vehicle_number: "",
        registration_date: "",
        note: "",
        active: true,
      },
      defaultItem: {
        id: "",
        vector_id: 0,
        types_of_services_id: 0,
        vehicle_number: "",
        registration_date: "",
        note: "",
        active: true,
      },
    }
  },
  methods: {
    async save() {
      if (this.user.user_rule === 'vector') {
        this.editedItem.vector_id = this.user.user.id
      }
      const res = await this.axios.post('vehicle', this.editedItem)
      this.$swal.fire({
        title: 'Salvataggio Veicolo',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      this.close();
    },
    close() {
      this.$refs["edit-modal-new-vehicle"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
  mounted() {
    const that = this
    setTimeout(function () {
      if (that.user.user_rule === 'vector' && that.$refs.vectorSelect !== undefined) {
        that.$refs.vectorSelect.value = that.user.user.id
        that.$refs.vectorSelect.disable = true
        that.editedItem.vector_id = that.user.user.id
      }
    }, 400)
  },
  computed: {
    user() {
      return this.$store.state.authentication;
    }
  }
}
</script>

<style scoped>

</style>